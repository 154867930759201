import React from "react"

import { db } from "../firebase"

type Actions =
  | { type: "RESET" }
  | { type: "SET_DATA"; data: any }
  | { type: "SET_LOADING" }
  | { type: "SET_ERROR"; error: string }

type State<T> = {
  loading: boolean
  loaded: boolean
  data?: T
  error?: string
}

const defaultState: State<any> = {
  loading: false,
  loaded: false,
  data: undefined,
  error: undefined,
}

const makeReducer: <T>() => React.Reducer<State<T>, Actions> =
  () => (state, action) => {
    switch (action.type) {
      case "RESET":
        return { loading: false, loaded: false }
      case "SET_LOADING":
        return { ...state, loading: !state.data && !state.loaded }
      case "SET_DATA":
        return { ...state, loading: false, loaded: true, data: action.data }
      case "SET_ERROR":
        return { ...state, loading: false, loaded: true, error: action.error }
    }
  }

export function useObjectOnce<T>(key: string): {
  loading: boolean
  loaded: boolean
  data: T | undefined
} {
  const [state, dispatch] = React.useReducer(makeReducer<T>(), defaultState)
  React.useEffect(() => {
    dispatch({ type: "RESET" })
    const timeout = setTimeout(() => {
      dispatch({ type: "SET_LOADING" })
    }, 500)

    db.ref(key)
      .once("value")
      .then(v => {
        const data = v.val()
        const key = v.key
        dispatch({ type: "SET_DATA", data: { ...data, _KEY: key } })
      })
      .catch(e => dispatch({ type: "SET_ERROR", error: e.message }))

    return () => {
      clearTimeout(timeout)
    }
  }, [key])

  return {
    loading: state.loading,
    loaded: state.loaded,
    data: state.data,
  }
}

export function useObject<T>(
  key: string,
  db_ = db
): { loading: boolean; loaded: boolean; data: T | undefined } {
  const [state, dispatch] = React.useReducer(makeReducer<T>(), defaultState)
  React.useEffect(() => {
    dispatch({ type: "RESET" })
    const timeout = setTimeout(() => {
      dispatch({ type: "SET_LOADING" })
    }, 500)

    const ref = db_.ref(key)
    ref.on("value", v => {
      const data = v.val()
      const key = v.key
      if (data) {
        dispatch({ type: "SET_DATA", data: { ...data, _KEY: key } })
      } else {
        dispatch({ type: "SET_DATA", data: undefined })
      }
    })

    return () => {
      ref.off()
      clearTimeout(timeout)
    }
  }, [db_, key])

  return {
    loading: state.loading,
    loaded: state.loaded,
    data: state.data,
  }
}

export function useListOnce<T>(key: string): {
  loading: boolean
  data: { _KEY: string; value: T }[] | undefined
} {
  const [state, dispatch] = React.useReducer(
    makeReducer<{ _KEY: string; value: T }[]>(),
    defaultState
  )
  React.useEffect(() => {
    dispatch({ type: "RESET" })
    const timeout = setTimeout(() => {
      dispatch({ type: "SET_LOADING" })
    }, 500)

    db.ref(key)
      .orderByKey()
      .once("value")
      .then(v => {
        const data = v.val() ?? {}
        const values = Object.keys(data).map(key => ({
          value: data[key],
          _KEY: key,
        }))
        dispatch({ type: "SET_DATA", data: values })
      })
      .catch(e => dispatch({ type: "SET_ERROR", error: e.message }))

    return () => {
      clearTimeout(timeout)
    }
  }, [key])

  return {
    loading: state.loading,
    data: state.data,
  }
}

export function useList<T>(
  key: string,
  db_ = db
): {
  loading: boolean
  loaded: boolean
  data: { _KEY: string; value: T }[] | undefined
} {
  const [state, dispatch] = React.useReducer(
    makeReducer<{ _KEY: string; value: T }[]>(),
    defaultState
  )
  React.useEffect(() => {
    dispatch({ type: "RESET" })
    const timeout = setTimeout(() => {
      dispatch({ type: "SET_LOADING" })
    }, 500)

    const ref = db_.ref(key)

    ref.orderByKey().on("value", v => {
      const data = v.val() ?? {}
      const values = Object.keys(data).map(key => ({
        value: data[key],
        _KEY: key,
      }))
      dispatch({ type: "SET_DATA", data: values })
    })

    return () => {
      clearTimeout(timeout)
      ref.off()
    }
  }, [db_, key])

  return {
    loading: state.loading,
    loaded: state.loaded,
    data: state.data,
  }
}
