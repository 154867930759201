import React from "react"

import { RouteComponentProps } from "@reach/router"
import { format } from "date-fns"
import { enUS, sv } from "date-fns/locale"
import { useIntl } from "gatsby-plugin-intl"
import usePromise from "react-promise-suspense"
import { useDatabaseObjectData } from "reactfire"

import {
  ListItem,
  ListItemButton,
  ListItemSubtitle,
  ListItemTitle,
  P,
  shevy,
  Stack,
} from "../components"
import SEO from "../components/Seo"
import { auth, db } from "../firebase"
import CheckIcon from "../svg/check-correct.svg"
import { useTranslations } from "../utils/intl"

import { Heading, Section, SmallH2 } from "./components"

const FormatTimestamp: React.FC<{ timestamp: number }> = ({ timestamp }) => {
  const intl = useIntl()

  try {
    return format(timestamp, "d MMMM yyyy", {
      locale: intl.locale == "sv" ? sv : enUS,
    }) as any
  } catch (e) {
    console.log(timestamp)
    return "Ogiltigt datum"
  }
}

function useUserAdminOrganizations() {
  const adminForOrganizations = Object.keys(
    useDatabaseObjectData<Record<string, true>>(
      db.ref(`/users/${auth.currentUser?.uid}/organizationAdmin`)
    ).data ?? {}
  )
  return adminForOrganizations
}

const LicenseRow: React.FC<{ orgId: string }> = ({ orgId }) => {
  const org = useDatabaseObjectData<{
    name: string
    payment?: { until: number }
  }>(db.ref(`/organizations/${orgId}`)).data

  if (!org.name) return <></>

  return (
    <ListItem to={`/logged-in/licenses/${orgId}`}>
      <ListItemTitle>{org.name}</ListItemTitle>
      <ListItemSubtitle>
        {org.payment && (
          <>
            Giltig tills <FormatTimestamp timestamp={org.payment.until} />
          </>
        )}
      </ListItemSubtitle>
    </ListItem>
  )
}

interface OrganizationsResponse {
  canCreateOrganizations: boolean
  groups: {
    admins: Record<string, true>
    created: number
    name: string
    package: number
    used: number
    id: string
    members: {
      email: string
      id: string
    }[]

    inviteLink: string
    payment: {
      transactionId: "invoice-1611161643355"
      until: 1642697644548
    }
  }[]
}

async function getMyOrganizations() {
  const response: OrganizationsResponse = await (
    await fetch(
      "https://us-central1-runbit-ddf6c.cloudfunctions.net/myOrganizations",
      {
        headers: {
          Authorization: "Bearer " + (await auth.currentUser?.getIdToken()),
        },
      }
    )
  ).json()

  return response.groups
}

export const LicenseInfo: React.FC<RouteComponentProps<{ orgId: string }>> = ({
  orgId,
}) => {
  const { it } = useTranslations()
  const org = (
    usePromise(getMyOrganizations, []) as OrganizationsResponse["groups"]
  ).find(s => s.id == orgId)

  if (!org) {
    return (
      <Section>
        <Heading
          title={it(["Couldn't find license", "Kunde inte hitta licensen"])}
        />
      </Section>
    )
  }

  return (
    <Section>
      <SEO title={it([`License for ${org.name}`, `Licens för ${org.name}`])} />
      <Heading
        title={it([`License for ${org.name}`, `Licens för ${org.name}`])}
      />
      <P>
        {it([
          <></>,
          <>
            Bjud in medlemmar med följande länk:{" "}
            <a href={org.inviteLink}>{org.inviteLink}</a>
          </>,
        ])}
      </P>
      <SmallH2>
        {it([
          `Members (${org.members.length})`,
          `Medlemmar (${org.members.length} st)`,
        ])}
      </SmallH2>
      {org.members.length === 0 && (
        <P>
          {it([
            "Nobody has registered with your link yet.",
            "Ingen har registrerat sig med er länk än.",
          ])}
        </P>
      )}
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
          gridGap: shevy.baseSpacing(0.5),
        }}
      >
        {org.members.map(member => (
          <ListItemButton key={member.email + member.id}>
            <ListItemTitle css={{ fontSize: "0.8em" }}>
              {member.email}
            </ListItemTitle>
            <ListItemSubtitle>
              {member.id === "unregistered" ? (
                it(["Unregistered", "Oregistrerad"])
              ) : (
                <>
                  <img
                    src={CheckIcon}
                    css={{
                      height: "1em",
                      verticalAlign: "top",
                    }}
                  />{" "}
                  {it(["Registered", "Registrerad"])}
                </>
              )}
            </ListItemSubtitle>
          </ListItemButton>
        ))}
      </div>
    </Section>
  )
}

export const Licenses: React.FC<RouteComponentProps> = () => {
  const { it } = useTranslations()

  const adminForGroups = useUserAdminOrganizations()

  return (
    <>
      <SEO title={it(["Licenses", "Licenser"])} />
      <Section>
        <Heading title={it(["Licenses", "Licenser"])} />

        {adminForGroups.length > 0 && (
          <>
            <P>Följande licenser är betalade:</P>
            <Stack>
              {adminForGroups.map(orgId => (
                <LicenseRow key={orgId} orgId={orgId} />
              ))}
            </Stack>
          </>
        )}
      </Section>
    </>
  )
}
